<template>
  <div>
    <v-dialog
      v-model="isAddNewBooking"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('addBooking') }}
        </v-card-title>
        <v-form
          ref="formAddBooking"
          @submit.prevent="createBooking"
        >
          <v-card-text>
            <label for="">{{ $t("date") }}</label>
            <v-text-field
              v-model="dataAdd.booking_date"
              readonly
              outlined
              dense
            ></v-text-field>
            <label for="">{{ $t("time") }}</label>
            <v-text-field
              v-model="bookingAddLocal.section_time"
              readonly
              outlined
              dense
            ></v-text-field>
            <label for="">{{ $t("title_list_appointment") }}</label>
            <v-text-field
              v-model="dataAdd.book_name"
              readonly
              outlined
              dense
            ></v-text-field>
            <label for="">{{ $t("appointment_details") }}</label>
            <!-- <v-textarea
              v-model="booking_value"
              :rules="[required]"
              outlined
              dense
            ></v-textarea> -->
            <quill-editor
              ref="myTextEditor"
              v-model="booking_value"
              :options="editorOption"
              class="pb-2"
            >
            </quill-editor>
          </v-card-text>
          <v-divider class="mt-0"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t("save") }}
            </v-btn>
            <v-btn
              outlined
              color="secondary"
              @click="$emit('update:is-add-new-booking')"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import booking from '@/api/appointment/booking'
import store from '@/store'
import { required } from '@/@core/utils/validation'
import { i18n } from '@/plugins/i18n'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
  },
  model: {
    prop: 'isAddNewBooking',
    event: 'update:is-add-new-booking',
  },
  props: {
    isAddNewBooking: {
      type: Boolean,
      default: false,
    },
    dataAdd: {
      type: Object,
      default: () => ({}),
    },
    dataBookingAdd: {
      type: Object,
      default: () => ({}),
    },

  },
  setup(props, { emit }) {
    const formAddBooking = ref(false)
    const dataAddLocal = ref({})
    const bookingAddLocal = ref({})
    const loading = ref(false)
    const booking_value = ref('')
    const { bookingAdd } = booking

    const createBooking = () => {
      const isFormValid = formAddBooking.value.validate()
      if (!isFormValid) return
      loading.value = true
      bookingAdd({
        booking_date: dataAddLocal.value.booking_date,
        section_id: bookingAddLocal.value.section_id,
        book_id: dataAddLocal.value.book_id,
        booking_value: booking_value.value,
        lang: i18n.locale,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-add-new-booking', false)
        loading.value = false
        emit('onAdd')
      })
    }

    watch(() => props.dataAdd, newvalue => {
      dataAddLocal.value = JSON.parse(JSON.stringify(newvalue))
    })
    watch(() => props.isAddNewBooking, () => {
      booking_value.value = ''
    })

    watch(() => props.dataBookingAdd, newvalue => {
      bookingAddLocal.value = JSON.parse(JSON.stringify(newvalue))
    })

    const editorOption = {
      placeholder: '',
      modules: {
        toolbar: {
          container: [
            [{ list: 'ordered' }, { list: 'bullet' }], // List
            [{ indent: '-1' }, { indent: '+1' }], // Indent
          ],
          handlers: {
            image(val) {
              if (val) {
                document.querySelector('.quill-img input').click()
              } else {
                this.quill.format('image', false)
              }
            },
          },
        },
      },
    }

    return {
      booking_value,
      formAddBooking,
      dataAddLocal,
      bookingAddLocal,
      loading,
      createBooking,
      required,
      editorOption,
    }
  },
}
</script>
<style lang="scss">
//@import '@core/preset/preset/apps/user.scss';

.ql-toolbar.ql-snow {
    border: 1px solid #ccc;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    box-sizing: border-box;
    font-family: 'Kanit', sans-serif;
    padding: 8px;
}

.ql-container {
  box-sizing: border-box;
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  height: 200px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin: 0px;
  position: relative;
}

</style>
