<template>
  <div>
    <v-card :loading="loading">
      <v-card-title class="px-2">
        {{ $t('booking') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          :loading="exportLoading"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          <v-icon class="me-1">
            {{ icons.mdiFileExcelOutline }}
          </v-icon>
          <span
            class="d-none d-sm-block"
          >  {{ $t('excel') }}</span>
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          fab
          icon
          outlined
          :loading="exportLoading"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          <v-icon>
            {{ icons.mdiFileExcelOutline }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="px-2 mb-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-dialog
            ref="dateStart"
            v-model.trim="modal"
            :return-value.sync="booking_date"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model.trim="booking_date"
                :label="$t('date')"
                hide-details
                readonly
                dense
                outlined
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model.trim="booking_date"
              class="set-font"
              :locale="$i18n.locale"
              scrollable
            >
              <v-spacer></v-spacer>

              <v-btn
                text
                color="primary"
                @click="$refs.dateStart.save(booking_date)"
              >
                {{ $t("confirm") }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="modal = false"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th
                v-for="(item, index) in bookingTitleList"
                :key="index"
              >
                {{ item.book_name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(booking, index) in bookingList"
              :key="index"
            >
              <td>
                {{ booking.section_time }}
              </td>
              <td
                v-for="(item, x) in booking.section_booking"
                :key="x"
              >
                <v-tooltip
                  v-if="!item.booking_value"
                  top
                  color="#212121"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      v-on="on"
                      @click="dataAdd = item;dataBookingAdd=booking;isAddNewBooking = true"
                    >
                      <v-icon
                        class="primary--text"
                      >
                        {{ icons.mdiPlusCircle }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("add_appointments") }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="item.booking_value"
                  top
                  color="#212121"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      @click="booking_id = item.booking_id;isDelete =true"
                      v-on="on"
                    >
                      <v-icon class="error--text">
                        {{ icons.mdiDeleteOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("delete") }}</span>
                </v-tooltip>
                <v-tooltip
                  v-if="item.booking_value"
                  top
                  color="#212121"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      icon
                      color="primary"
                      v-on="on"
                      @click="dataEdit = item;dataBookingEdit=booking;isEditBooking = true"
                    >
                      <v-icon class="primary--text">
                        {{ icons.mdiPencilOutline }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("edit") }}</span>
                </v-tooltip>
                <span
                  v-if="item.booking_value"
                  style="font-size: 17px"
                  v-html="item.booking_value"
                >
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <AddNewBooking
      v-model="isAddNewBooking"
      :data-add="dataAdd"
      :data-booking-add="dataBookingAdd"
      @onAdd="getBookingList"
    />
    <EditBooking
      v-model="isEditBooking"
      :data-edit="dataEdit"
      :data-booking-edit="dataBookingEdit"
      @onUpdate="getBookingList"
    />
    <RemoveBooking
      :id="booking_id"
      v-model="isDelete"
      @onDelete="getBookingList"
    />
  </div>
</template>

<script>
import {
  mdiFileExcelOutline, mdiCheck, mdiClose, mdiPencilOutline, mdiPlusCircle, mdiDeleteOutline,
} from '@mdi/js'
import useMoreApppointment from './useMoreApppointment'
import AddNewBooking from './AddNewBooking.vue'
import EditBooking from './EditBooking.vue'
import RemoveBooking from './RemoveBooking.vue'

export default {
  components: {
    AddNewBooking,
    EditBooking,
    RemoveBooking,
  },

  setup() {
    return {
      ...useMoreApppointment(),
      icons: {
        mdiFileExcelOutline, mdiCheck, mdiClose, mdiPencilOutline, mdiPlusCircle, mdiDeleteOutline,
      },
    }
  },
}
</script>
