import { onMounted, ref, watch } from '@vue/composition-api'
import booking from '@/api/appointment/booking'
import { i18n } from '@/plugins/i18n'

export default function useMoreApppointment() {
  const moment = require('moment')
  const modal = ref(false)
  const booking_date = ref(moment(new Date()).format('YYYY-MM-DD'))
  const bookingTitleList = ref([])
  const bookingList = ref([])
  const loading = ref(false)
  const { bookingList: bookingListGet } = booking

  const isAddNewBooking = ref(false)
  const dataAdd = ref({})
  const dataBookingAdd = ref({})

  const isEditBooking = ref(false)
  const dataEdit = ref({})
  const dataBookingEdit = ref({})

  const isDelete = ref(false)
  const booking_id = ref('')

  const exportLoading = ref(false)
  const XLSX = require('xlsx')
  const head = ref([])
  const dataBook = ref([])

  onMounted(() => {
    getBookingList()
  })

  const getBookingList = async () => {
    loading.value = true
    bookingTitleList.value = []
    bookingListGet({
      booking_date: booking_date.value,
    }).then(res => {
      bookingList.value = res
      res[0].section_booking.forEach(element => {
        bookingTitleList.value.push(element)
      })
      bookingTitleList.value.unshift({
        book_name: i18n.t('time'),
      })
      mapBookgin()
      loading.value = false
    })
  }

  watch([booking_date], () => {
    getBookingList()
  })

  const mapBookgin = () => {
    head.value = [i18n.t('time')]
    if (bookingList.value.length > 0 && bookingList.value[0].section_booking.length > 0) {
      // Map Hade
      bookingList.value[0].section_booking.forEach(item => {
        head.value.push(item.book_name)
      })

      // Map Data
      try {
        dataBook.value = bookingList.value.map(item => {
          const tmp = {
            time: item.section_time,
          }
          for (let i = 0; i < head.value.length; i++) {
            tmp[i] = item.section_booking[i] && item.section_booking[i].booking_value
              ? item.section_booking[i].booking_value
              : ''
          }

          return tmp
        })
      } catch (e) {
        console.log(e)
        console.log('Bug Map Book Data')
      }
    }
  }
  const exportExcel = () => {
    exportLoading.value = true
    const fileName = `${i18n.t('additional_appointments')}.xlsx`

    const Heading = [
      [`${i18n.t('additional_appointments')}`],
      head.value,
    ]

    const bodyHade = ['time']
    for (let i = 0; i < head.value; i++) {
      bodyHade.push(i)
    }

    const ws = XLSX.utils.aoa_to_sheet(Heading)
    XLSX.utils.sheet_add_json(ws, dataBook.value, {
      header: bodyHade,
      skipHeader: true,
      origin: -1,
    })
    const wb = XLSX.utils.book_new()
    const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }]
    const wscols = [
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
    ]
    ws['!merges'] = merge
    ws['!cols'] = wscols
    XLSX.utils.book_append_sheet(wb, ws, 'sheet')

    /* พิมร์ files */
    XLSX.writeFile(wb, fileName)
    setTimeout(() => {
      exportLoading.value = false
    }, 1500)
  }

  return {
    isDelete,
    booking_id,
    isEditBooking,
    dataEdit,
    dataBookingEdit,
    dataBookingAdd,
    isAddNewBooking,
    dataAdd,
    loading,
    modal,
    booking_date,
    bookingTitleList,
    bookingList,
    exportLoading,
    exportExcel,
    getBookingList,

  }
}
