<template>
  <div>
    <v-dialog
      v-model="isEditBooking"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title>
          {{ $t('editBooking') }}
        </v-card-title>
        <v-form
          ref="formEditBooking"
          @submit.prevent="updateBooking"
        >
          <v-card-text>
            <label for="">{{ $t("date") }}</label>
            <v-text-field
              v-model="dataEditLocal.booking_date"
              readonly
              outlined
              dense
            ></v-text-field>
            <label for="">{{ $t("time") }}</label>
            <v-text-field
              v-model="bookingEditLocal.section_time"
              readonly
              outlined
              dense
            ></v-text-field>
            <label for="">{{ $t("title_list_appointment") }}</label>
            <v-text-field
              v-model="dataEditLocal.book_name"
              readonly
              outlined
              dense
            ></v-text-field>
            <label for="">{{ $t("appointment_details") }}</label>
            <!-- <v-textarea
              v-model="dataEditLocal.booking_value"
              :rules="[required]"
              outlined
              dense
            ></v-textarea> -->
            <quill-editor
              ref="myTextEditor"
              v-model="dataEditLocal.booking_value"
              :options="editorOption"
              class="pb-2"
            >
            </quill-editor>
          </v-card-text>
          <v-divider class="mt-0"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading"
            >
              {{ $t("save") }}
            </v-btn>
            <v-btn
              outlined
              color="secondary"
              @click="$emit('update:is-update-booking')"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import booking from '@/api/appointment/booking'
import store from '@/store'
import { required } from '@/@core/utils/validation'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
  },
  model: {
    prop: 'isEditBooking',
    event: 'update:is-update-booking',
  },
  props: {
    isEditBooking: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
    dataBookingEdit: {
      type: Object,
      default: () => ({}),
    },

  },
  setup(props, { emit }) {
    const formEditBooking = ref(false)
    const dataEditLocal = ref({})
    const bookingEditLocal = ref({})
    const loading = ref(false)
    const { bookingUpdate } = booking

    const updateBooking = () => {
      const isFormValid = formEditBooking.value.validate()
      if (!isFormValid) return
      loading.value = true
      bookingUpdate({ ...dataEditLocal.value, ...bookingEditLocal.value }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('update:is-update-booking', false)
        loading.value = false
        emit('onUpdate')
      })
    }

    watch(() => props.dataEdit, newvalue => {
      dataEditLocal.value = JSON.parse(JSON.stringify(newvalue))
    })

    watch(() => props.dataBookingEdit, newvalue => {
      bookingEditLocal.value = JSON.parse(JSON.stringify(newvalue))
    })

    const editorOption = {
      placeholder: '',
      modules: {
        toolbar: {
          container: [
            [{ list: 'ordered' }, { list: 'bullet' }], // List
            [{ indent: '-1' }, { indent: '+1' }], // Indent
          ],
          handlers: {
            image(val) {
              if (val) {
                document.querySelector('.quill-img input').click()
              } else {
                this.quill.format('image', false)
              }
            },
          },
        },
      },
    }

    return {
      formEditBooking,
      dataEditLocal,
      bookingEditLocal,
      loading,
      updateBooking,
      required,
      quillEditor,
      editorOption,
    }
  },
}
</script>
